import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueProgressBar from 'vue-progressbar'
import VueGeolocation from 'vue-browser-geolocation';

Vue.config.productionTip = false

Vue.use(VueGeolocation)
Vue.use(VueProgressBar, {
  color: '#f5d046',
  failedColor: '#ff0000',
  thickness: '5px',
  transition: {
    speed: '1s',
    opacity: '0.5s',
    termination: 1000
  },
  position: 'fixed',
  autoRevert: true,
  location: 'top',
  inverse: false
})

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
