<template>
  <div id="app" class="vax-locator">
    <vue-progress-bar></vue-progress-bar>
    <div class="vax-locator__head">
      <a class="vax-locator__logo" href="https://scdhec.gov/vaxfacts" rel="noopener" target="_blank">DHEC</a>
      <button
        class="language-toggle"
        @click="changeLanguage()"
      >{{ languageToggleText }}</button>
    </div>

    <div class="vax-locator__wrapper">
      <div class="vax-locator__intro">
        <p class="vax-locator__vaccine-logo" v-html="content.logo"></p>

        <div class="vax-locator__home-content-top">
          <h2>{{ content.page_title }}</h2>
          <div v-html="content.intro"></div>
        </div>

        <div class="vax-locator__filter-buttons">
          <p @click="filterByCurrentLocation" 
            :class="[
              'vax-locator__filter-button',
              'vax-locator__filter-button--by-location',
              { 'vax-locator__filter-button--by-location--loading': isFilteringByLocation }
          ]">{{ isBrowserCoordinates ? content.filter_button_prefix_past : content.filter_button_prefix }} {{ content.filter_button_suffix }}</p>
          <a class="vax-locator__filter-button vax-locator__filter-button--map" href="https://gis.dhec.sc.gov/vax" rel="noopener" target="_blank">{{ content.map_button }}</a>
          <a class="vax-locator__filter-button vax-locator__filter-button--vams" href="https://guest.vams.cdc.gov/?jurisdiction=SC" rel="noopener" target="_blank">{{ content.vams_button }}</a>
        </div>
        <div class="vax-locator__filter">
          <input class="vax-locator__filter-zip"
              type="text"
              pattern="\d*"
              maxlength="5"
              :placeholder="content.input_placeholder"
              v-model="filterZipInput"
              @keyup.enter="applyFilters"
              @keypress="filterZipInput.length === 0 ? errorMessage = '': false"
            />
            <button class="vax-locator__filter-submit" @click="applyFilters">{{ content.magnifying_glass }}</button>
        </div>
        <transition name="closing">
          <p class="vax-locator__error" v-if="errorMessage.length">{{ errorMessage }}</p>
        </transition>
      </div>

      <div class="vax-locator__body" v-show="isFiltered">
        <transition name="fade" mode="out-in">
          <router-view/>
        </transition>
      </div>

      <div class="vax-locator__feet" v-html="content.footer"></div>
    </div>

    <!-- loading screen -->
    <transition name="fade" mode="out-in">
      <div class="vax-locator__loading-screen" v-if="!isDataFetchedFromServer">
        <img src="~@/assets/logo-dhec.svg" alt="DHEC">
        <h2>Loading...</h2>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import content from '@/assets/content'
export default {
  data () {
    return {
      filterZipInput: '',
      errorMessage: '',
      isFilteringByLocation: '',
    }
  },
  components: {
  },
  mounted () {
  },
  methods: {
    ...mapMutations([
      'SET_LANGUAGE'
    ]),
    ...mapActions([
      'filter',
      'prefilter',
      'clearFilter',
      'loadLocations',
    ]),
    applyFilters() {
      this.errorMessage = ''
      this.filter(this.filterZipInput)
        .catch((err) => {
          this.errorMessage = err.message
        })
    },
    filterByCurrentLocation() {
      this.isFilteringByLocation = true;
      this.$getLocation({
        enableHighAccuracy: true,
        timeout: 15000, // 15 seconds
        maximumAge: 0,
      }).then(coordinates => {
        console.log('user provided coordinates: ', coordinates)
        this.filterZipInput = '';
        this.errorMessage = '';
        this.clearFilter();
        this.prefilter(coordinates);
        this.isFilteringByLocation = false;
      }).catch(err => {
        console.error(err)
        this.isFilteringByLocation = false;
      });
    },
    changeLanguage () {
      console.log('hey')
      if (this.language === 'en') {
        this.$router.push({ name: 'Home', query: { l: 'es' } })
      } else {
        this.$router.push({ name: 'Home', query: { l: 'en' } })
      }
    }
  },
  computed: {
    ...mapState([
      'language',
      'content',
      'filterZip',
      'isDataFetchedFromServer',
      'isFiltered',
      'hasSearched'
    ]),
    ...mapGetters([
      'isBrowserCoordinates',
    ]),
    languageToggleText () {
      if (this.language === 'en') {
        return content.es.language_selection
      }
      return content.en.language_selection
    }
  },
  watch: {
    filterZipInput: function(newVal) {
      if (!newVal.length) {
        this.errorMessage = '';
      }
    },
    filterZip: function(newVal) {
      if (!newVal) {
        this.filterZipInput = ''
      }
    }
  }
}
</script>

<style lang="scss">
@import url("https://use.typekit.net/lod5kfr.css");
* {
  box-sizing: border-box;
}
html {
  min-height: 100vh;
}
body {
  background: $yellowGradient;
  color: $black;
  font-family: $gothic;
}
.language-toggle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: rem(15px);
  padding: 0;
  font-family: $gothic;
  font-weight: bold;
  font-size: rem(14px);
  border: 0;
  color: #FFF;
  background-color: transparent;
  transform: translate3d(0,-50%,0);
  @include bp($small) {
    right: rem(20px);
    font-size: rem(16px);
  }
  &:hover {
    text-decoration: underline;
  }
}
.vax-locator {
  &__wrapper {
    max-width: rem(960px);
    margin: 0 auto;
  }

  &__head {
    position: relative;
    background: $blue;
    color: #fff;
    padding: .5rem 1rem;
  }
  &__logo {
    background: url('~@/assets/logo-dhec.svg') center center no-repeat;
    background-size: contain;
    display: block;
    text-indent: -9999px;
    width: rem(82px);
    height: rem(30px);
    margin: 0 auto;
  }
  &__loading-screen {
    background: $blue;
    color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  &__intro {
    padding: 1rem 2rem;
    @include bp($large) {
      padding: 1rem 0;
    }
  }
  &__feet {
    padding: 1rem 2rem;
    text-align: center;
    p {
      border-top: 3px solid rgba(#2C2E35, .27);
      padding-top: 3rem;
    }
  }
  &__filter {
    position: relative;
    &-buttons {
      @include bp($medium) {
        display: flex;
        align-items: center;
      }
    }
    &-button {
      font-size: .875rem;
      color: $blue;
      cursor: pointer;
      border: 2px solid #1216EB;
      background: #1216EB;
      color: #fff;
      display: inline-block;
      padding: .75rem 1rem;
      text-decoration: none;
      position: relative;
      margin: 0 0 1rem 0;
      display: inline-block;
      margin-right: 1rem;

      &:hover {
        text-decoration: underline;
      }

      &--by-location {
        padding-left: 3rem;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: .5rem;
          top: .25rem;
          width: rem(32px);
          height: rem(32px);
          background-image: url('~@/assets/ui-map-marker.svg');
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
          text-align: center;
        }
      }

      &--by-location--loading {
        padding-left: 3rem;
        &:before {
          background-image: url('~@/assets/ui-loading.svg');
        }
      }

      &--map {
        padding-left: 3rem;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: .5rem;
          top: .25rem;
          width: rem(32px);
          height: rem(32px);
          background-image: url('~@/assets/ui-map-regular.svg');
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
          text-align: center;
        }
      }

      &--vams {
        background: #fff;
        color: #1216EB;
        font-weight: 700;
        border: 3px solid #1216EB;
      }
    }
    &-zip {
      padding: 1rem;
      background: #FFFFFF;
      border: 3px solid #1216EB;
      font-family: $gothic;
      color: #1216EB;
      border: 3px solid #1216EB;
      width: 100%;
      padding-right: 3rem
    }
    &-submit {
      height: 1rem;
      width: 1rem;
      border: 0;
      text-indent: -9999px;
      padding: 1.55rem;
      background-position: center center;
      background-image: url('~@/assets/ui-search.svg');
      background-color: transparent;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      cursor: pointer;
    }
  }
  &__vaccine-logo {
    height: rem(60px);
    margin: 0;
    font-family: $gothic-condensed;
    font-weight: 800;
    font-size: rem(25px);
    line-height: rem(59px);
    letter-spacing: rem(2px);
    text-align: center;
    text-transform: uppercase;
    color: $blue;
    // background-image: url('~@/assets/logo-vaccine.svg');
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position: center top;
    span {
      display: inline-block;
      margin: 0 0 0 rem(7px);
      padding: 0 rem(10px);
      color: #ffe55d;
      background-color: $blue;
    }
  }
  &__error {
    color: $red;
    font-family: $gothic-condensed;
    letter-spacing: .5px;
    margin: 0;
  }
  &__home-content-top {
    color: #1216EB;
    h2 {
      font-family: $gothic-condensed;
      font-size: rem(65px);
      text-align: center;
      font-weight: 800;
      text-transform: uppercase;
    }
    p {
      max-width: rem(850px);
      margin: 1rem auto;
      font-family: $gothic;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
      @include bp($medium) {
        font-size: rem(18px);
      }
      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
.fade {
  &-enter-active,
  &-leave-active {
    transition: all .25s ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

.slideHide {
  &-enter-active,
  &-leave-active {
    transition: all .25s ease;
  }

  &-enter,
  &-leave-to {
    position: absolute;
    overflow: hidden;
    opacity: 0;
    padding: 0;
    width: 100%;
    transform: translateY(-5%);
  }
}

.closing {
  &-enter-active,
  &-leave-active {
    transition: all .25s ease;
  }

  &-enter,
  &-leave-to {
    position: absolute;
    overflow: hidden;
    opacity: 0;
    transform: translateY(-5%);
  }
}

.magic {
  transition: all .25s ease-in-out;
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: scale3d(0,0,0);
  }
  &-enter-to {
    opacity: 1;
    transform: scale3d(1,1,1);
  }
  &-leave-active {
    position: absolute;
  }
  &-move {
    opacity: 1;
    transition: all .25s ease-in-out;
  }
}

/*
Trade Gothic Next LT Pro Bold
font-family: trade-gothic-next, sans-serif;
font-weight: 700;
font-style: normal;

Trade Gothic Next LT Pro Bold Italic
font-family: trade-gothic-next, sans-serif;
font-weight: 700;
font-style: italic;

Trade Gothic Next LT Pro Italic
font-family: trade-gothic-next, sans-serif;
font-weight: 400;
font-style: italic;

Trade Gothic Next LT Pro Regular
font-family: trade-gothic-next, sans-serif;
font-weight: 400;
font-style: normal;

*/
</style>
