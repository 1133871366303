import axios from '../util/api-axios'
import Vue from 'vue'
import Vuex from 'vuex'

import { distance } from '@/util/utils'
import SCZipcodes from '@/assets/sc-zip-codes'
import content from '@/assets/content'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: 'en',
    content: content.en,
    locationsAll: [],
    locationsFiltered: [],
    filterZip: null,
    isDataFetchedFromServer: false,
    zipcodes: SCZipcodes,
    isFiltered: false,
    hasSearched: false
  },
  mutations: {
    SET_LANGUAGE (state, language) {
      state.language = language
      state.content = content[language]
    },
    SET_LOCATIONS (state, locations) {
      state.locationsAll = locations
      state.isDataFetchedFromServer = true
    },
    SET_BROWSER_COORDINATES (state, coordinates) {
      state.browserCoordinates = coordinates
    },

    SET_FILTER_ZIP (state, zip) { state.filterZip = zip },
    CLEAR_FILTER_ZIP (state) { state.filterZip = null },

    SET_FLAG_IS_FILTERED (state, boolean) { state.isFiltered = boolean },
    SET_FLAG_HAS_SEARCHED (state, boolean) { state.hasSearched = boolean },
  },
  actions: {
    loadLocations({ commit }) {
      console.log('LOADLOCATIONS')
      console.log(process.env.VUE_APP_API)
      axios
        .get(process.env.VUE_APP_API)
        .then((res) => {
          console.log('DATA: ', res.data);
          const { features } = res.data;
          commit('SET_LOCATIONS', features);
        })
        .catch(err => {
          console.err(err);
        })
    },
    prefilter({ dispatch, commit }, browserCoordinates) {
      const coordinates = {
        latitude: browserCoordinates.lat,
        longitude: browserCoordinates.lng,
      }
      dispatch('sortLocations', coordinates)
      commit('SET_BROWSER_COORDINATES', coordinates)
    },
    async filter ({ commit, dispatch, state }, zip) {
      const foundZip = await state.zipcodes.find(zipcodeData => +zipcodeData.fields.zipcode === +zip)
      try {
        dispatch('sortLocations', {
          latitude: foundZip.fields.lat,
          longitude: foundZip.fields.long,
        })
        commit('SET_FILTER_ZIP', foundZip);
      }
      catch {
        dispatch('clearFilter');
        throw Error('Please enter a valid SC zip code')
      }
    },
    sortLocations({ commit, state }, currentCoordinates) {
      state.locationsAll.sort((locationA, locationB) => {
        const coordinatesA = {
          longitude: locationA.geometry.x,
          latitude: locationA.geometry.y
        }
        const coordinatesB = {
          longitude: locationB.geometry.x,
          latitude: locationB.geometry.y,
        };

        const distanceA = distance(
          currentCoordinates.latitude,
          currentCoordinates.longitude,
          coordinatesA.latitude,
          coordinatesA.longitude,
        );
        const distanceB = distance(
          currentCoordinates.latitude,
          currentCoordinates.longitude,
          coordinatesB.latitude,
          coordinatesB.longitude,
        );
        return distanceA - distanceB;
      })

      commit('SET_FLAG_IS_FILTERED', true);
      commit('SET_FLAG_HAS_SEARCHED', true);
    },
    clearFilter ({ commit, state }) {
      commit('CLEAR_FILTER_ZIP');
      commit('SET_FLAG_IS_FILTERED', false);

      if (state.browserCoordinates) {
        state.locationsAll.sort((locationA, locationB) => {
          const coordinatesA = {
            longitude: locationA.geometry.x,
            latitude: locationA.geometry.y
          }
          const coordinatesB = {
            longitude: locationB.geometry.x,
            latitude: locationB.geometry.y,
          };
  
          const distanceA = distance(
            state.browserCoordinates.latitude,
            state.browserCoordinates.longitude,
            coordinatesA.latitude,
            coordinatesA.longitude,
          );
          const distanceB = distance(
            state.browserCoordinates.latitude,
            state.browserCoordinates.longitude,
            coordinatesB.latitude,
            coordinatesB.longitude,
          );
          return distanceA - distanceB;
        })
      }
    }
  },
  getters: {
    locations: (state) => {
      const activatedLocations = state.locationsAll.filter(loc => loc.attributes.Activated1 === 'Yes')
      const yes_appointments = [];
      const no_appointments = [];

      activatedLocations.map(loc => {
        loc.attributes.Appointments === 'Yes'
          ? yes_appointments.push(loc)
          : no_appointments.push(loc)
      })

      const wovenLocations = [
        ...yes_appointments,
        ...no_appointments
      ]

      return state.isFiltered
        ? wovenLocations.slice(0, 75)
        : wovenLocations
    },
    isBrowserCoordinates: (state) => state.isFiltered && state.filterZip === null
  }
})
