<template>
  <div class="vax-locator__results">
    <transition name="slideHide">
      <div class="vax-locator__status" v-if="isFiltered">
        <span>{{ content.result_count_prefix }} {{ locations.length }} {{ content.result_count_suffix }}</span>
        <button class="vax-locator__clear" @click="clearFilter">{{ content.clear_button }}</button>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <transition-group tag="ul" name="magic" class="vax-locator__list" v-if="isFiltered">
        <template v-for="(location) in locations">
          <li class="vax-location" v-if="location.attributes.Activated1 === 'Yes'" :key="location.attributes.OBJECTID">
            <h3 class="vax-location__name">
              {{ location.attributes.loc_name }}
              {{ location.attributes.loc_type1 ? `(${getLocationTypeText(location.attributes.loc_type1)})` : '' }}
            </h3>
            <p :class="[
              'vax-location__status',
              location.attributes.Appointments === 'Yes'
                ? 'vax-location__status--accepting'
                : 'vax-location__status--not-accepting'
            ]">
              {{
                location.attributes.Appointments === 'Yes'
                  ? content.appointments_available
                  : content.appointments_unavailable
              }}
            </p>
            <p class="vax-location__status vax-location__status--accepting" v-if="location.attributes.drive_thru === 'Yes'">
              Drive-Thru
            </p>
            <p
              v-if="location.attributes.V_Manufacturer"
              class="vax-location__manufacturer"
            >{{ content.vaccine_manufacturer }}: {{ getVaccineManufacturer(location.attributes.V_Manufacturer) }}</p>
            <p class="vax-location__address">
              {{ location.attributes.SiteAddress }},
              {{ location.attributes.SiteAddressDetail ? location.attributes.SiteAddressDetail + ', ' : '' }}
              {{ location.attributes.SiteCity }},
              {{ location.attributes.SiteState }}
              {{ location.attributes.SiteZip }}
            </p>
            <a v-if="location.attributes.SitePhone"
              class="vax-location__phone"
              :href="`tel:${location.attributes.SitePhone}`"
            >
              {{ location.attributes.SitePhone}}
            </a>
            
            <a
              v-if="location.attributes.URL"
              class="vax-location__register-link"
              :href="location.attributes.URL"
              :aria-label="`Contact Provider ${location.attributes.loc_name} at ${location.attributes.SiteAddress}`"
            >{{ content.contact_link }}</a>
          </li>
        </template>
      </transition-group>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import content from '@/assets/content'
import { manufacturerIdMap } from '@/util/utils'

export default {
  name: 'Home',
  components: {
  },
  mounted () {
    this.loadLocations();
    console.log(content.location_types)
  },
  methods: {
    ...mapActions([
      'loadLocations',
      'clearFilter',
    ]),
    getLocationTypeText (type) {
      if (this.language === 'en') {
        return type
      }
      if (!content.location_types[type]) {
        return type
      }
      return content.location_types[type][this.language]
    },
    getVaccineManufacturer (m) {
      if (m === null) { return '' }
      let fullTextArray = []
      m = m.replace(/ /g, '').split(',')
      m.forEach(i => {
        if (typeof manufacturerIdMap[i.toLowerCase()] === 'string') {
          fullTextArray.push(manufacturerIdMap[i.toLowerCase()])
        } else {
          fullTextArray.push(i)
        }
      })
      return fullTextArray.join(', ')
    }
  },
  computed: {
    ...mapState([
      'language',
      'content',
      'isFiltered'
    ]),
    ...mapGetters([
      'locations'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .vax-locator {
    &__results {
      background: #fff;
    }

    &__status {
      background: $blue;
      color: #fff;
      padding: 1rem;
      font-family: $gothic-condensed;
      letter-spacing: 1px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__list {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    &__clear {
      cursor: pointer;
      display: inline-block;
      position: relative;
      padding: rem(10px) rem(22px) rem(10px) 0;
      font-family: $gothic-condensed;
      text-transform: uppercase;
      letter-spacing: rem(1px);
      border: 0;
      color: #FFDC2D;
      background-color: transparent;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        width: rem(16px);
        height: rem(16px);
        margin: rem(-8px) 0 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-image: url('~@/assets/ui-clear-x.svg');
      }
    }
  }

  .vax-location {
    padding: 1rem;
    &__name {
      margin: 0 auto .5rem;
    }
    &__status {
      font-size: rem(14px);
      &--accepting {
        color: $green;
      }
      &--not-accepting {
        color: $red;
      }
    }
    &__phone {
      color: $black;
      display: block;
      margin: 0 auto .5rem;
      &:hover {
        text-decoration: none;
      }
    }
    &__register-link {
      font-size: rem(12px);
      color: #1216EB;
      letter-spacing: 0.43px;
      text-transform: uppercase;
      font-weight: 700;
      &:hover {
        text-decoration: none;
      }
    }
    & + .vax-location {
      border-top: 2px solid #eee;
    }
    p {
      margin: 0 auto .5rem;
    }
  }
</style>